import 'firebase/compat/auth';

import firebase from 'firebase/compat/app';
// import admin from 'firebase-admin';

// admin.initializeApp({
//   credential: admin.credential.cert(serviceAccount)
// });

if (!firebase.apps.length) {
  firebase.initializeApp(
    {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    },
    'primary'
  );
}

const auth = firebase.auth();

// export const adminAuth = admin.auth();

/**
 * Logs in a user with the provided email and password.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<Object>} - A promise that resolves to the logged-in user object.
 * @throws {Error} - If there is an error logging in.
 */
export const login = async (email, password) => {
  try {
    const userCredential = await auth.signInWithEmailAndPassword(email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error logging in:', error.message, error.stack);
    throw error;
  }
};

/**
 * Signs up a new user with the provided email and password.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<Object>} - A promise that resolves to the new user object.
 * @throws {Error} - If there is an error signing up.
 */
export const signup = async (email, password) => {
  try {
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing up:', error.message, error.stack);
    throw error;
  }
};

/**
 * Logs out the user.
 * @async
 * @throws {Error} If there is an error logging out.
 */
export const logout = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error('Error logging out:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates the user's password.
 * @async
 * @param {string} newPassword - The new password.
 * @param {string} password - The current Password
 * @throws {Error} If there is an error updating the password.
 */
export const changePassword = async (newPassword, password) => {
  try {
    const user = auth.currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(user.email, password);
    await user.reauthenticateWithCredential(cred);
    await user.updatePassword(newPassword);
  } catch (error) {
    console.error('Error updating password:', error.message, error.stack);
    throw error;
  }
};

/**
 * Sends a password reset email to the user.
 * @async
 * @param {string} email - The user's email.
 * @throws {Error} If there is an error sending the password reset email.
 */
export const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
  } catch (error) {
    console.error('Error sending password reset email:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates the email address of the current user.
 *
 * @param {string} newEmail - The new email address.
 * @param {string} password - The user's current password.
 * @throws {Error} If there is an error updating the email address.
 */
export const updateEmail = async (newEmail, password) => {
  try {
    const user = auth.currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(user.email, password);
    await user.reauthenticateWithCredential(cred);
    await user.updateEmail(newEmail);
  } catch (error) {
    console.error('Error updating email:', error.message, error.stack);
    throw error;
  }
};
