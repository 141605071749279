// import 'firebase/compat/firestore';

// import { createSlice } from '@reduxjs/toolkit';
// import firebase from 'firebase/compat/app';

// const firestore = firebase.firestore();

// const users = createSlice({
//   name: 'users',
//   initialState: {
//     users: []

//   },
//   reducers: {
//     addUserSuccess: (state, action) => {
//       const newUser = action.payload;
//       firestore
//         .collection('clients')
//         .add(newUser)
//         .then((doc) => {
//           firestore.collection('clients').doc(doc.id).update({ id: doc.id });
//         });
//     },
//     updateUserSuccess: (state, action) => {
//       const updatedUser = action.payload;
//       firestore.collection('clients').doc(updatedUser.id).update(updatedUser);
//     },
//     deleteUserSuccess: (state, action) => {
//       const userId = action.payload;
//       firestore.collection('clients').doc(userId).delete();
//     },
//     getUsersSuccess: (state, action) => {
//       return action.payload;
//     }
//   }
// });

// export const { addUserSuccess, updateUserSuccess, deleteUserSuccess, getUsersSuccess } = users.actions;

// export const fetchUsers = () => async (dispatch) => {
//   const usersRef = firestore.collection('clients');
//   const snapshot = await usersRef.get();
//   const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//   dispatch(getUsersSuccess(users));
// };

// export const addUser = (user) => async (dispatch) => {
//   dispatch(addUserSuccess(user));
// };

// export default users.reducer;

import 'firebase/compat/firestore';

import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';
import { dispatch } from 'store';

const firestore = firebase.firestore();

const initialState = {
  companies: [],
  users: [],
  branches: []
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersSuccess: (state, action) => {
      state.users = action.payload;
    },
    getBranchesSuccess: (state, action) => {
      state.branches = action.payload;
    },
    addBranchSuccess: (state, action) => {
      state.branches.push(action.payload);
    },
    updateBranchSuccess: (state, action) => {
      const index = state.branches.findIndex((branch) => branch.id === action.payload.id);
      if (index !== -1) {
        state.branches[index] = action.payload;
      }
    },
    deleteBranchSuccess: (state, action) => {
      state.branches = state.branches.filter((branch) => branch.id !== action.payload);
    },
    getCompaniesSuccess: (state, action) => {
      state.companies = action.payload;
    },
    addCompanySuccess: (state, action) => {
      state.companies.push(action.payload);
    }
  }
});

export const {
  addUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  getUsersSuccess,
  getBranchesSuccess,
  addBranchSuccess,
  updateBranchSuccess,
  deleteBranchSuccess,
  addCompanySuccess,
  getCompaniesSuccess
} = usersSlice.actions;

// export const fetchUsers = () => async (dispatch) => {
//   try {
//     const usersRef = firestore.collection('users');
//     const snapshot = await usersRef.get();
//     const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//     dispatch(getUsersSuccess(users));
//   } catch (error) {
//     console.error('Error fetching users: ', error);
//   }
// };
export const fetchUsers = (userId) => async (dispatch) => {
  try {
    const usersRef = firestore.collection('users').where('ownerUid', '==', userId);
    const snapshot = await usersRef.get();
    const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch(getUsersSuccess(users));
  } catch (error) {
    console.error('Error fetching users: ', error);
  }
};

export const addUser = (userId, users) => async () => {
  try {
    // const docRef = await firestore.collection('users').add(users);
    const docRef = await firestore.collection('users').add({
      ...users,
      ownerUid: userId
    });
    await docRef.update({ id: docRef.id });
    dispatch(addUserSuccess({ id: docRef.id, ...user }));
  } catch (error) {
    console.error('Error adding user: ', error);
  }
};

export const updateUser = (user) => async () => {
  try {
    await firestore.collection('users').doc(user.id).update(user);
  } catch (error) {
    console.error('Error updating user: ', error);
  }
};

export const deleteUser = (userId) => async () => {
  try {
    await firestore.collection('users').doc(userId).delete();
    dispatch(deleteUserSuccess(userId));
  } catch (error) {
    console.error('Error deleting user: ', error);
  }
};

// Branches CRUD
// export const fetchBranches = () => async (dispatch) => {
//   try {
//     const branchesRef = firestore.collection('branches');
//     const snapshot = await branchesRef.get();
//     const branches = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//     dispatch(getBranchesSuccess(branches));
//   } catch (error) {
//     console.error('Error fetching branches: ', error);
//   }
// };
export const fetchBranches = (userId) => async (dispatch) => {
  try {
    const branchesRef = firestore.collection('branches').where('ownerUid', '==', userId);
    const snapshot = await branchesRef.get();
    const branches = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch(getBranchesSuccess(branches));
  } catch (error) {
    console.error('Error fetching branches: ', error);
  }
};

export const addBranch = (userId, branch) => async (dispatch) => {
  try {
    // const docRef = await firestore.collection('branches').add(branch);
    const docRef = await firestore.collection('branches').add({
      ...branch,
      ownerUid: userId
    });
    await docRef.update({ id: docRef.id });
    dispatch(addBranchSuccess({ id: docRef.id, ...branch }));
  } catch (error) {
    console.error('Error adding branch: ', error);
  }
};

export const updateBranch = (branch) => async (dispatch) => {
  try {
    await firestore.collection('branches').doc(branch.id).update(branch);
    dispatch(updateBranchSuccess(branch));
  } catch (error) {
    console.error('Error updating branch: ', error);
  }
};

export const deleteBranch = (branchId) => async (dispatch) => {
  if (!branchId) {
    console.error('Invalid branchId: ', branchId);
    return;
  }

  try {
    await firestore.collection('branches').doc(branchId).delete();
    dispatch(deleteBranchSuccess(branchId));
  } catch (error) {
    console.error('Error deleting branch: ', error);
  }
};

// add company

// export const addCompany = (userId, data) => async () => {
//   try {
//     // const docRef = await firestore.collection('companies').add(data);
//     const docRef = await firestore.collection('companies').doc(userId).set(data);
//     await docRef.update({ id: docRef.id });
//     dispatch(addCompanySuccess({ id: docRef.id, ...user }));
//   } catch (error) {
//     console.error('Error adding company: ', error);
//   }
// };

export const fetchClients = () => async (dispatch) => {
  const usersRef = firestore.collection('clients');
  const snapshot = await usersRef.get();
  const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  dispatch(getCompaniesSuccess(users));
};
export default usersSlice.reducer;
