// project-imports
// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAllAssets,
  getAllBankingType,
  getAllChartOfAccounts,
  getAllCompanies,
  getAllPayroll,
  getAllReportingPeriods
} from '../../contexts/FirestoreServices';

export const fetchCompanies = createAsyncThunk('setups/fetchCompanies', async (_, { rejectWithValue }) => {
  try {
    const response = await getAllCompanies();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchChartOfAccounts = createAsyncThunk('setups/fetchChartOfAccounts', async (_, { rejectWithValue }) => {
  try {
    const response = await getAllChartOfAccounts();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchReportingPeriods = createAsyncThunk('setups/fetchReportingPeriods', async (_, { rejectWithValue }) => {
  try {
    const response = await getAllReportingPeriods();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchBankingTypes = createAsyncThunk('setups/fetchBankingTypes', async (_, { rejectWithValue }) => {
  try {
    const response = await getAllBankingType();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchClassAssets = createAsyncThunk(
  'setups/fetchClassAssets',
  async ({ companyId = null, limit = 10, lastItem = null }, { rejectWithValue }) => {
    try {
      const response = await getAllAssets(companyId, 'classes', limit, lastItem);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchLocationAssets = createAsyncThunk(
  'setups/fetchLocationAssets',
  async ({ companyId = null, limit = 10, lastItem = null }, { rejectWithValue }) => {
    try {
      const response = await getAllAssets(companyId, 'location', limit, lastItem);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchDepreciationAssets = createAsyncThunk(
  'setups/fetchDepreciationAssets',
  async ({ companyId = null, limit = 10, lastItem = null }, { rejectWithValue }) => {
    try {
      const response = await getAllAssets(companyId, 'depreciation', limit, lastItem);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPayroll = createAsyncThunk(
  'setups/fetchPayroll',
  async ({ companyId = null, category, limit = 10, lastItem = null }, { rejectWithValue }) => {
    try {
      const response = await getAllPayroll(companyId, category, limit, lastItem);
      const filteredResponse = response.filter((payroll) => payroll.category === category);
      return filteredResponse;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// ==============================|| SLICE - SETUPS ||============================== //

const initialState = {
  error: null,
  companies: [],
  accountypes: [],
  reportingperiods: [],
  bankingtypes: [],
  classAssets: [],
  locationAssets: [],
  depreciationAssets: [],
  payrolls: []
};

const setups = createSlice({
  name: 'setups',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ACCOUNT TYPES
    getAccountTypes(state, action) {
      state.accountypes = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.companies = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchChartOfAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChartOfAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.accountypes = action.payload;
      })
      .addCase(fetchChartOfAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchReportingPeriods.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReportingPeriods.fulfilled, (state, action) => {
        state.loading = false;
        state.reportingperiods = action.payload;
      })
      .addCase(fetchReportingPeriods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchBankingTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBankingTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.bankingtypes = action.payload;
      })
      .addCase(fetchBankingTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchClassAssets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClassAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.classAssets = action.payload;
      })

      .addCase(fetchClassAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchLocationAssets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLocationAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.locationAssets = action.payload;
      })
      .addCase(fetchLocationAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchDepreciationAssets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDepreciationAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.depreciationAssets = action.payload;
      })
      .addCase(fetchDepreciationAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPayroll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPayroll.fulfilled, (state, action) => {
        state.loading = false;
        state.payrolls = action.payload;
      })
      .addCase(fetchPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { getAccountTypes } = setups.actions;

export default setups.reducer;
