// project-imports
import clientmenu from './clients-menu';
import setups from './setups';
import tjpmenu from './tjp-menu';

// Get usertype from localStorage
const usertype = localStorage.getItem('usertype');

// Initialize menuItems
const menuItems = {
  items: []
};

// Define user types
const tjpUserTypes = ['tjp-admin', 'tjp-user', 'tjp-branch-admin', 'tjp-branch-user', 'tjp-company-admin', 'tjp-company'];

const clientUserTypes = ['admin', 'ceo', 'supervisor', 'manager', 'accountant'];

// Add menu items based on usertype
if (tjpUserTypes.includes(usertype)) {
  menuItems.items.push(tjpmenu, setups);
} else if (clientUserTypes.includes(usertype)) {
  menuItems.items.push(clientmenu);
}

export default menuItems;
