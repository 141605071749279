// assets
import { Bill, Calendar1, Kanban, KyberNetwork, Messages2, People, Profile2User, ShoppingBag, UserSquare } from 'iconsax-react';

// third-party
import { FormattedMessage } from 'react-intl';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  people: People
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const clientmenu = {
  id: 'group-clients',
  icon: icons.applications,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.calendar,
      breadcrumbs: true
    },
    {
      id: 'chat',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      url: '/users',
      icon: icons.customer,
      breadcrumbs: true
    },
    {
      id: 'branch',
      title: <FormattedMessage id="Branches" />,
      type: 'item',
      url: '/branches',
      icon: icons.people,
      breadcrumbs: true
    }
  ]
};

export default clientmenu;
