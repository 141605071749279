// assets

import {
  Backward5Seconds,
  Bag,
  Bank,
  Box,
  Box2,
  ChemicalGlass,
  DocumentFilter,
  Fatrows,
  Location,
  LocationTick,
  Money,
  Money2,
  Money3,
  MoneyArchive,
  MoneyTime,
  Moneys,
  OmegaCircle,
  PresentionChart,
  Rank,
  Story
} from 'iconsax-react';

// third-party
import { FormattedMessage } from 'react-intl';

// icons
const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart,
  banking: Bank,
  location: Location,
  classes: Box,
  assets: Box2,
  accounts: DocumentFilter,
  payroll: MoneyTime,
  allowances: MoneyArchive,
  rank: Rank,
  relief: Moneys,
  deductions: Money3,
  ec: Money2,
  paye: Money,
  chemical: ChemicalGlass,
  class: OmegaCircle,
  locationTick: LocationTick,
  depreciation: Backward5Seconds,
  bank: Bank,
  types: Bag
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const setups = {
  id: 'group-widget',
  title: <FormattedMessage id="setups" />,
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'chart',
      title: <FormattedMessage id="Reporting Period" />,
      type: 'item',
      url: '/accounts/reporting-period',
      icon: icons.chart
    },
    {
      id: 'chart_accounts',
      title: <FormattedMessage id="Chart Of Accounts" />,
      type: 'collapse',
      icon: icons.accounts,
      children: [
        {
          id: 'accounts',
          title: <FormattedMessage id=" Accounts" />,
          type: 'collapse',
          children: [
            {
              id: 'types',
              title: <FormattedMessage id="Types" />,
              type: 'item',
              url: '/accounts/types'
            },
            {
              id: 'categories',
              title: <FormattedMessage id="Categories" />,
              type: 'item',
              url: '/accounts/categories'
            }
          ]
        },
        {
          id: 'setups-trial-balance',
          title: <FormattedMessage id="Trial Balance" />,
          type: 'item',
          url: '/setups/trial-balance'
        },
        {
          id: 'setups-income-surplus',
          title: <FormattedMessage id="Income Surplus" />,
          type: 'item',
          url: '/setups/income-surplus'
        },
        {
          id: 'setups-cost-of-sales',
          title: <FormattedMessage id="Cost Of Sales" />,
          type: 'item',
          url: '/setups/cost-of-sales'
        }
      ]
    },
    {
      id: 'payroll',
      title: <FormattedMessage id="Payroll" />,
      type: 'collapse',
      icon: icons.payroll,
      children: [
        {
          id: 'allowances',
          title: <FormattedMessage id="Allowances" />,
          type: 'item',
          url: '/setups/payroll/allowances',
          icon: icons.allowances
        },
        {
          id: 'rank',
          title: <FormattedMessage id="Rank" />,
          type: 'item',
          url: '/setups/payroll/rank',
          icon: icons.rank
        },
        {
          id: 'relief',
          title: <FormattedMessage id="Relief" />,
          type: 'item',
          url: '/setups/payroll/relief',
          icon: icons.relief
        },
        {
          id: 'deductions',
          title: <FormattedMessage id="Deductions" />,
          type: 'item',
          url: '/setups/payroll/deductions',
          icon: icons.deductions
        },
        {
          id: 'ec',
          title: <FormattedMessage id="Employer Cost" />,
          type: 'item',
          url: '/setups/payroll/employer-cost',
          icon: icons.ec
        },
        {
          id: 'paye',
          title: <FormattedMessage id="P.A.Y.E" />,
          type: 'item',
          url: '/setups/payroll/paye',
          icon: icons.paye
        }
      ]
    },
    {
      id: 'assets',
      title: <FormattedMessage id="Assets" />,
      type: 'collapse',
      icon: icons.chemical,
      children: [
        {
          id: 'assets-classes',
          title: <FormattedMessage id="Classes" />,
          type: 'item',
          url: '/setups/assets/classes',
          icon: icons.classes
        },
        {
          id: 'assets-location',
          title: <FormattedMessage id="Location" />,
          type: 'item',
          url: '/setups/assets/location',
          icon: icons.location
        },
        {
          id: 'assets-depreciation',
          title: <FormattedMessage id="Depreciation" />,
          type: 'item',
          url: '/setups/assets/depreciation',
          icon: icons.depreciation
        }
      ]
    },
    {
      id: 'banking',
      title: <FormattedMessage id="Banking" />,
      type: 'item',
      url: '/setups/banking-types',
      icon: icons.banking
    }
  ]
};

export default setups;
