import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import firebase from 'firebase/compat/app';
import { signup } from './FirebaseAuthServices';

if (!firebase.apps.length) {
  firebase.initializeApp(
    {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    },
    'primary'
  );
}

//Second firebase connection for account creation
var admin = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  },
  'second'
);

const db = firebase.firestore();
const storage = firebase.storage();

const adminCollection = 'admins';
const companyCollection = 'companies';
const branchCollection = 'branches';
const userCollection = 'users';

///Database

/**
 * Adds a super admin to the Firestore database.
 *
 * @param {Object} data - The data of the super admin to be added.
 * @param {string} data.firstName - The first name of the super admin.
 * @param {string} data.lastName - The last name of the super admin.
 * @param  {string} data.email - The email of the super admin.
 * @param {string} data.password - The password of the super admin.
 * @param {string} data.phoneNumber - The phone number of the super admin.
 * @param {string} data.role - [Admin,Finance]The role of the super admin.
 * @returns {void} - A promise that resolves when the super admin is added successfully.
 * @throws {Error} - If there is an error adding the super admin.
 */
export const addSuperAdmin = async (data) => {
  try {
    var user = await signup(data.email, data.password);
    delete data.password;
    data.id = user.uid;
    await db.collection(adminCollection).doc(data.id).set(data);
  } catch (error) {
    console.error('Error adding super admin:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all super admins from the Firestore database.
 * @returns {Promise<Array<Object>>} An array of super admin objects.
 * @throws {Error} If there is an error retrieving the super admins.
 */
export const getAllSuperAdmin = async () => {
  try {
    const snapshot = await db.collection(adminCollection).get();
    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all super admins:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves the super admin data by ID from Firestore.
/**
 * @param {string} id - The ID of the super admin.
 * @returns {Promise<Object>} - A promise that resolves to the super admin data.
 * @throws {Error} - If there is an error retrieving the super admin data.
 */
export const getSuperAdminById = async (id) => {
  try {
    const snapshot = await db.collection(adminCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting super admin by id:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates the super admin data in Firestore.
 * @param {string} id - The ID of the super admin.
 * @param {Object} data - The data to update.
 * @returns {Promise<void>} - A promise that resolves when the super admin is updated successfully.
 */
export const updateSuperAdmin = async (id, data) => {
  try {
    await db.collection(adminCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating super admin:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a super admin from the Firestore database.
 * @param {string} id - The ID of the super admin to delete.
 * @throws {Error} If there is an error deleting the super admin.
 */
export const deleteSuperAdmin = async (id) => {
  try {
    await db.collection(adminCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting super admin:', error.message, error.stack);
    throw error;
  }
};

/**
 * Adds a company and contact to Firestore.
 * First call signup() form FirebaseAuth before calling this function.
 * @param {Object} companyData - The data of the company to be added.
 * @param {string} companyData.name - The name of the company.
 * @param {string} companyData.location - The location of the company.
 * @param {string} companyData.email - The email of the company.
 * @param {string} companyData.phoneNumber - The phone number of the company.
 * @param {string} companyData.startDate - start date of the company.
 * @param {string} companyData.endDate - end date of the company.
 * @param {string} companyData.status - status of the company.
 * @param {string} companyData.logo - (file) The logo url of the company.
 * @param {Object} contactData - The data of the contact to be added.
 * @param {string} contactData.name - The name of the contact.
 * @param {string} contactData.email - The email of the contact.
 * @param {string} contactData.password - The password of the contact.
 * @param {string} contactData.phoneNumber - The phone number of the contact.
 * @param {string} contactData.status - The status of the contact.
 * @param {string} contactData.role - The role of the contact.
 * @returns {Promise<void>} - A promise that resolves when the company and contact are added successfully.
 * @throws {Error} - If there is an error adding the company and contact.
 */
export const addCompany = async (companyData, contactData) => {
  try {
    var cred = await admin.auth().createUserWithEmailAndPassword(contactData.email, contactData.password);
    companyData.id = cred.user.uid;
    contactData.id = cred.user.uid;
    delete contactData.password;
    admin.auth().signOut();
    if (companyData.logo) {
      companyData.logo = await uploadLogo(companyData.logo, companyData.id);
    }
    await db.collection(companyCollection).doc(companyData.id).set(companyData);
    await db
      .collection(userCollection)
      .doc(contactData.id)
      .set({
        ...contactData,
        companyId: companyData.id,
        branchId: null
      });
  } catch (error) {
    console.error('Error adding company & contact:', error.message, error.stack);
    throw error;
  }
};

/**
 * Adds a company user to the Firestore database.
 *
 * @param {string} companyId - The ID of the company.
 * @param {string} branchId - The ID of the branch.
 * @param {object} userData - The user data.
 * @param {string} userData.firstName - The first name of the user.
 * @param {string} userData.otherName - The other name of the user.
 * @param {string} userData.phoneNumber - The phone number of the user.
 * @param {string} userData.email - The email of the user.
 * @param {string} userData.password - The password of the user.
 * @param {string} userData.role - The role of the user.
 * @param {string} userData.userType - The type of the user.
 * @returns {Promise<void>} - A promise that resolves when the user is added successfully.
 * @throws {Error} - If an error occurs while adding the user.
 */
export const addCompanyUser = async (companyId, branchId, userData) => {
  // try {
  var cred = await admin.auth().createUserWithEmailAndPassword(userData.email, userData.password);
  userData.id = cred.user.uid;
  admin.auth().signOut();
  delete userData.password;
  await db
    .collection(userCollection)
    .doc(userData.id)
    .set({ ...userData, companyId, branchId });
  // } catch (error) {
  //   throw error;
  // }
};

/**
 * Retrieves all companies from Firestore.
 *
 * @param {number} [limit=10] - The maximum number of companies to retrieve.
 * @param {any} [lastItem=null] - The last item to start retrieving companies from.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of company objects.
 * @throws {Error} - If there is an error retrieving the companies.
 */
export const getAllCompanies = async (limit = 10, lastItem = null) => {
  if (typeof limit !== 'number' || limit <= 0) {
    throw new Error('Limit must be a positive number');
  }

  try {
    let snapshot;
    if (lastItem) {
      snapshot = await db.collection(companyCollection).startAfter(lastItem).limit(limit).get();
    } else {
      snapshot = await db.collection(companyCollection).limit(limit).get();
    }
    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error in getAllCompanies:', { limit, lastItem, message: error.message, stack: error.stack });
    throw error;
  }
};

/**
 * Updates a company document in Firestore.
 * @param {string} id - The ID of the company document.
 * @param {Object} data - The data to update the company document with.
 * @throws {Error} If there is an error updating the company document.
 */
export const updateCompany = async (id, data) => {
  try {
    await db.collection(companyCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating company:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves a company by its ID from Firestore.
 * @param {string} id - The ID of the company to retrieve.
 * @returns {Promise<Object>} A promise that resolves to the company data.
 * @throws {Error} If there is an error retrieving the company.
 */
export const getCompanyById = async (id) => {
  try {
    const snapshot = await db.collection(companyCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting company:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a company from the Firestore database.
 * @param {string} id - The ID of the company to delete.
 * @throws {Error} If there is an error deleting the company.
 */
export const deleteCompany = async (id) => {
  try {
    await db.collection(companyCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting company:', error.message, error.stack);
    throw error;
  }
};

/**
 * Adds a company branch to Firestore.
 *
 * @param {string} companyId - The ID of the company.
 * @param {Object} branchData - The data of the branch to be added.
 * @param {string} branchData.name - The name of the branch.
 * @param {string} branchData.location - The location of the branch.
 * @param {string} branchData.email - The email of the branch.
 * @param {string} branchData.phoneNumber - The phone number of the branch.
 * @returns {Promise<void>} - A promise that resolves when the branch is successfully added.
 * @throws {Error} - If there is an error adding the branch.
 */
export const addCompanyBranch = async (companyId, branchData) => {
  try {
    // Generate branch id
    const branchRef = db.collection(companyCollection).doc(companyId).collection(branchCollection).doc();
    branchData.id = branchRef.id;
    await branchRef.set(branchData);
  } catch (error) {
    console.error('Error adding branch:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all branches of a company from Firestore.
 * @param {string} companyId - The ID of the company.
 * @param {number} [limit=10] - The maximum number of branches to retrieve. Default is 10.
 * @param {any} [lastItem=null] - The last item to start retrieving branches from. Default is null.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of branch objects.
 * @throws {Error} - If there is an error retrieving the branches.
 */
export const getAllCompanyBranches = async (companyId, limit = 10, lastItem = null) => {
  try {
    let snapshot;
    if (lastItem) {
      snapshot = await db.collection(companyCollection).doc(companyId).collection(branchCollection).startAfter(lastItem).limit(limit).get();
    } else {
      snapshot = await db.collection(companyCollection).doc(companyId).collection(branchCollection).limit(limit).get();
    }
    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all company branches:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates a company branch in Firestore.
 *
 * @param {string} companyId - The ID of the company.
 * @param {string} branchId - The ID of the branch.
 * @param {Object} data - The data to update.
 * @throws {Error} If there is an error updating the company branch.
 */
export const updateCompanyBranch = async (companyId, branchId, data) => {
  try {
    await db.collection(companyCollection).doc(companyId).collection(branchCollection).doc(branchId).update(data);
  } catch (error) {
    console.error('Error updating company:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves a company branch by its ID.
 * @param {string} companyId - The ID of the company.
 * @param {string} branchId - The ID of the branch.
 * @returns {Promise<Object>} A promise that resolves to the data of the company branch.
 * @throws {Error} If there is an error retrieving the company branch.
 */
export const getCompanyBranchById = async (companyId, branchId) => {
  try {
    const snapshot = await db.collection(companyCollection).doc(companyId).collection(branchCollection).doc(branchId).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting company:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a company branch from Firestore.
 *
 * @param {string} companyId - The ID of the company.
 * @param {string} branchId - The ID of the branch to be deleted.
 * @throws {Error} If there is an error deleting the company branch.
 */
export const deleteCompanyBranch = async (companyId, branchId) => {
  try {
    await db.collection(companyCollection).doc(companyId).collection(branchCollection).doc(branchId).delete();
  } catch (error) {
    console.error('Error deleting company:', error.message, error.stack);
    throw error;
  }
};

const reportingPeriodCollection = 'setup_reportingPeriods';

/**
 * Adds a reporting period to the Firestore database.
 * @param {Object} data - The data of the reporting period to be added.
 * @param {string} data.startDate - The start date of the reporting period.
 * @param {string} data.endDate - The end date of the reporting period.
 * @param {string} data.status - The status of the reporting period.
 * @param {string} data.companyId - The ID of the company.
 * @param {number} data.years - The accounting years.
 * @returns {Promise<void>} - A promise that resolves when the reporting period is successfully added.
 * @throws {Error} - If there is an error adding the reporting period.
 */
export const addReportingPeriod = async (data) => {
  try {
    let docRef = db.collection(reportingPeriodCollection).doc();
    data.id = docRef.id;
    await docRef.set(data);
  } catch (error) {
    console.error('Error adding reporting period:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all reporting periods from Firestore.
 *
 * @param {string|null} companyId - The ID of the company to filter the reporting periods by. Default is null.
 * @param {number} limit - The maximum number of reporting periods to retrieve. Default is 10.
 * @param {any} lastItem - The last item to start the query after. Default is null.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of reporting periods.
 * @throws {Error} - If there is an error retrieving the reporting periods.
 */
export const getAllReportingPeriods = async (companyId = null, limit = 10, lastItem = null) => {
  try {
    const query = db.collection(reportingPeriodCollection).limit(limit);

    if (companyId) {
      query.where('companyId', '==', companyId);
    }

    if (lastItem) {
      query.startAfter(lastItem);
    }

    const snapshot = await query.get();

    if (snapshot.empty) {
      return []; // Early return for empty data
    }

    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all reporting periods:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates the reporting period with the specified ID.
 *
 * @param {string} id - The ID of the reporting period.
 * @param {Object} data - The data to update the reporting period with.
 * @throws {Error} If an error occurs while updating the reporting period.
 */
export const updateReportingPeriod = async (id, data) => {
  try {
    await db.collection(reportingPeriodCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating reporting period:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves a reporting period by its ID from Firestore.
 * @param {string} id - The ID of the reporting period.
 * @returns {Promise<Object>} A promise that resolves to the data of the reporting period.
 * @throws {Error} If there is an error retrieving the reporting period.
 */
export const getReportingPeriodById = async (id) => {
  try {
    const snapshot = await db.collection(reportingPeriodCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting reporting period:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a reporting period from the Firestore database.
 * @param {string} id - The ID of the reporting period to delete.
 * @throws {Error} If there is an error deleting the reporting period.
 */
export const deleteReportingPeriod = async (id) => {
  try {
    await db.collection(reportingPeriodCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting reporting period:', error.message, error.stack);
    throw error;
  }
};

const chartOfAccountCollection = 'setup_chartOfAccounts';
/**
 * Adds a chart of account to Firestore.
 * @param {Object} data - The data of the chart of account to be added.
 * @param {string} data.name - The name of the chart of account.
 * @param {string} data.type - The code of the chart of account.
 * @param {string} data.companyId - The ID of the company.
 * @param {string} data.category - The code of the chart of account.
 * @throws {Error} If an error occurs while adding the chart of account.
 */
export const addChartOfAccount = async (data) => {
  // try {
  let docRef = db.collection(chartOfAccountCollection).doc();
  data.id = docRef.id;
  await docRef.set(data);
  // } catch (error) {
  //   throw error;
  // }
};

/**
 * Retrieves all chart of accounts from Firestore.
 *
 * @param {string|null} companyId - The ID of the company to filter the chart of accounts by. Default is null.
 * @param {number} limit - The maximum number of chart of accounts to retrieve. Default is 10.
 * @param {any} lastItem - The last item to start the query after. Default is null.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of chart of accounts objects.
 * @throws {Error} - If there is an error retrieving the chart of accounts.
 */
export const getAllChartOfAccounts = async (companyId = null, category = null, limit = 10, lastItem = null) => {
  try {
    let query = db.collection(chartOfAccountCollection).limit(limit);

    if (companyId) {
      query.where('companyId', '==', companyId);
    }
    if (category) {
      query.where('category', '==', category);
    }
    if (lastItem) {
      query.startAfter(lastItem);
    }
    const snapshot = await query.get();
    if (snapshot.empty) {
      return [];
    }
    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all chart of accounts:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates a chart of account in the Firestore database.
 *
 * @param {string} id - The ID of the chart of account to update.
 * @param {Object} data - The updated data for the chart of account.
 * @throws {Error} If there is an error updating the chart of account.
 */
export const updateChartOfAccount = async (id, data) => {
  try {
    await db.collection(chartOfAccountCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating chart of account:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves a chart of account by its ID from Firestore.
 * @param {string} id - The ID of the chart of account.
 * @returns {Promise<Object>} A promise that resolves to the chart of account data.
 * @throws {Error} If there is an error retrieving the chart of account.
 */
export const getChartOfAccountById = async (id) => {
  try {
    const snapshot = await db.collection(chartOfAccountCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting chart of account:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a chart of account from the Firestore database.
 * @param {string} id - The ID of the chart of account to be deleted.
 * @throws {Error} If there is an error deleting the chart of account.
 */
export const deleteChartOfAccount = async (id) => {
  try {
    await db.collection(chartOfAccountCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting chart of account:', error.message, error.stack);
    throw error;
  }
};

const payrollCollection = 'setup_payrolls';

/**
 * Adds a payroll to Firestore.
 * @param {Object} data - The data of the chart of account to be added.
 * @param {string} data.name - The name of the chart of account.
 * @param {string} data.type - The code of the chart of account.
 * @param {string} data.companyId - The ID of the company.
 * @param {string} data.category - The code of the chart of account.
 * @throws {Error} If an error occurs while adding the chart of account.
 */
export const addPayroll = async (data) => {
  try {
    let docRef = db.collection(payrollCollection).doc();
    data.id = docRef.id;
    await docRef.set(data);
  } catch (error) {
    console.error('Error adding payroll:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates a payroll document in Firestore.
 * @param {string} id - The ID of the payroll document.
 * @param {object} data - The data to update the payroll document with.
 * @throws {Error} If there is an error updating the payroll document.
 */
export const updatePayroll = async (id, data) => {
  try {
    await db.collection(payrollCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating payroll:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all payroll data from Firestore.
 *
 * @param {string|null} companyId - The ID of the company to filter by. If null, all companies will be included.
 * @param {string|null} category - The category to filter by. If null, all categories will be included.
 * @param {number} limit - The maximum number of payroll records to retrieve.
 * @param {any} lastItem - The last item in the previous batch of results. Used for pagination.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of payroll data objects.
 * @throws {Error} - If there is an error retrieving the data from Firestore.
 */
export const getAllPayroll = async (companyId = null, category = null, limit = 10, lastItem = null) => {
  try {
    const query = db.collection(payrollCollection).limit(limit);

    if (companyId) {
      query.where('companyId', '==', companyId);
    }
    if (category) {
      query.where('category', '==', category);
    }

    if (lastItem) {
      query.startAfter(lastItem);
    }

    const snapshot = await query.get();

    if (snapshot.empty) {
      return []; // Early return for empty data
    }

    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all reporting periods:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves a payroll document from Firestore by its ID.
 * @param {string} id - The ID of the payroll document.
 * @returns {Promise<Object>} A Promise that resolves to the payroll document data.
 * @throws {Error} If there is an error retrieving the payroll document.
 */
export const getPayrollById = async (id) => {
  try {
    const snapshot = await db.collection(payrollCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting payroll:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a payroll document from the Firestore database.
 * @param {string} id - The ID of the payroll document to be deleted.
 * @throws {Error} If there is an error deleting the payroll document.
 */
export const deletePayroll = async (id) => {
  try {
    await db.collection(payrollCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting payroll:', error.message, error.stack);
    throw error;
  }
};

const assetsCollections = 'setup_assets';

/**
 * Adds an asset to the Firestore database.
 * @param {Object} data - The data of the asset to be added.
 * @param {string} data.companyId - The ID of the company.
 * @param {string} data.category - The category of the asset.
 * @returns {Promise<void>} - A promise that resolves when the asset is successfully added.
 * @throws {Error} - If there is an error adding the asset.
 */
export const addAsset = async (data) => {
  try {
    let docRef = db.collection(assetsCollections).doc();
    data.id = docRef.id;
    await docRef.set(data);
  } catch (error) {
    console.error('Error adding asset:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all assets from Firestore.
 *
 * @param {string|null} companyId - The ID of the company to filter assets by. Defaults to null.
 * @param {string|null} category - The category of assets to filter by. Defaults to null.
 * @param {number} limit - The maximum number of assets to retrieve. Defaults to 10.
 * @param {any} lastItem - The last item to start retrieving assets from. Defaults to null.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of asset objects.
 * @throws {Error} - If there is an error retrieving the assets.
 */
export const getAllAssets = async (companyId = null, category = null, limit = 10, lastItem = null) => {
  try {
    let query = db.collection(assetsCollections).limit(limit);

    if (companyId) {
      query = query.where('companyId', '==', companyId);
    }
    if (category) {
      query = query.where('category', '==', category);
    }
    if (lastItem) {
      query = query.startAfter(lastItem);
    }

    const snapshot = await query.get();

    if (snapshot.empty) {
      return [];
    }

    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all assets:', error.message, error.stack);
    throw error;
  }
};
/**
 * Retrieves assets by ID from Firestore.
 * @param {string} id - The ID of the asset.
 * @returns {Promise<Object>} - A promise that resolves to the asset data.
 * @throws {Error} - If there is an error retrieving the asset.
 */
export const getAssetsById = async (id) => {
  try {
    const snapshot = await db.collection(assetsCollections).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting asset:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates the assets collection in Firestore with the provided data.
 *
 * @param {string} id - The ID of the asset to update.
 * @param {Object} data - The data to update the asset with.
 * @throws {Error} If there is an error updating the asset.
 */
export const updateAssets = async (id, data) => {
  try {
    await db.collection(assetsCollections).doc(id).update(data);
  } catch (error) {
    console.error('Error updating asset:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes an asset from the Firestore database.
 * @param {string} id - The ID of the asset to be deleted.
 * @throws {Error} If there is an error deleting the asset.
 */
export const deleteAssets = async (id) => {
  try {
    await db.collection(assetsCollections).doc(id).delete();
  } catch (error) {
    console.error('Error deleting asset:', error.message, error.stack);
    throw error;
  }
};

const bankingCollection = 'setup_banking';

/**
 * Adds a banking record to Firestore.
 * @param {Object} data - The data to be added.
 * @param {string} data.companyId - The ID of the company.
 * @param {string} data.category - The category of the asset.
 * @returns {Promise<void>} - A promise that resolves when the data is successfully added.
 * @throws {Error} - If there is an error adding the banking record.
 */
export const addBankingType = async (data) => {
  try {
    let docRef = db.collection(bankingCollection).doc();
    data.id = docRef.id;
    await docRef.set(data);
  } catch (error) {
    console.error('Error adding banking:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves banking data by ID from Firestore.
 * @param {string} id - The ID of the banking data to retrieve.
 * @returns {Promise<Object>} A promise that resolves to the banking data.
 * @throws {Error} If there is an error retrieving the banking data.
 */
export const getBankingTypeById = async (id) => {
  try {
    const snapshot = await db.collection(bankingCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting banking:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates the banking document with the specified ID in Firestore.
 *
 * @param {string} id - The ID of the banking document.
 * @param {Object} data - The data to update the banking document with.
 * @throws {Error} If there is an error updating the banking document.
 */
export const updateBankingType = async (id, data) => {
  try {
    await db.collection(bankingCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating banking:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all banking data from Firestore.
 *
 * @param {string|null} companyId - The ID of the company to filter by. If null, all companies will be included.
 * @param {string|null} category - The category to filter by. If null, all categories will be included.
 * @param {number} limit - The maximum number of documents to retrieve. Default is 10.
 * @param {any} lastItem - The last document to start the query after. If null, the query starts from the beginning.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of banking data objects.
 * @throws {Error} - If there is an error retrieving the data from Firestore.
 */
export const getAllBankingType = async (companyId = null, category = null, limit = 10, lastItem = null) => {
  try {
    const query = db.collection(bankingCollection).limit(limit);

    if (companyId) {
      query.where('companyId', '==', companyId);
    }
    if (category) {
      query.where('category', '==', category);
    }

    if (lastItem) {
      query.startAfter(lastItem);
    }

    const snapshot = await query.get();

    if (snapshot.empty) {
      return []; // Early return for empty data
    }

    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all banking:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a banking document from the Firestore database.
 * @param {string} id - The ID of the banking document to delete.
 * @throws {Error} If there is an error deleting the banking document.
 */
export const deleteBankingType = async (id) => {
  try {
    await db.collection(bankingCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting banking:', error.message, error.stack);
    throw error;
  }
};

const billAndInvoiceCollection = 'setup_billAndInvoice';

/**
 * Adds a bill and invoice to the Firestore database.
 * @param {Object} data - The data to be added.
 * @param {string} data.companyId - The ID of the company.
 * @param {string} data.type - The type of the bill and invoice.
 * @param {string} data.group - The group of the bill and invoice.
 * @param {string} data.categoryOne - The category One of the bill and invoice.
 * @param {string} data.categoryTwo - The category Two of the bill and invoice.
 * @param {string} data.categoryThree - The category Three of the bill and invoice.
 * @returns {Promise<void>} - A promise that resolves when the data is successfully added.
 * @throws {Error} - If there is an error adding the bill and invoice.
 */
export const addBillAndInvoice = async (data) => {
  try {
    let docRef = db.collection(billAndInvoiceCollection).doc();
    data.id = docRef.id;
    await docRef.set(data);
  } catch (error) {
    console.error('Error adding bill and invoice:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all bills and invoices from Firestore.
 *
 * @param {string|null} companyId - The ID of the company to filter by. If null, all companies will be included.
 * @param {string|null} type - The type of bill or invoice to filter by. If null, all types will be included.
 * @param {number} limit - The maximum number of documents to retrieve. Default is 10.
 * @param {any|null} lastItem - The last document to start the query after. If null, the query starts from the beginning.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of bill and invoice objects.
 * @throws {Error} - If there is an error retrieving the data from Firestore.
 */
export const getAllBillAndInvoice = async (companyId = null, type = null, limit = 10, lastItem = null) => {
  try {
    const query = db.collection(billAndInvoiceCollection).limit(limit);

    if (companyId) {
      query.where('companyId', '==', companyId);
    }
    if (type) {
      query.where('type', '==', type);
    }

    if (lastItem) {
      query.startAfter(lastItem);
    }

    const snapshot = await query.get();

    if (snapshot.empty) {
      return []; // Early return for empty data
    }

    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all bill and invoice:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates a bill and invoice document in Firestore.
 *
 * @param {string} id - The ID of the document to update.
 * @param {Object} data - The data to update the document with.
 * @throws {Error} If there is an error updating the document.
 */
export const updateBillAndInvoice = async (id, data) => {
  try {
    await db.collection(billAndInvoiceCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating bill and invoice:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves a bill or invoice from Firestore by its ID.
 * @param {string} id - The ID of the bill or invoice.
 * @returns {Promise<Object>} A promise that resolves to the data of the bill or invoice.
 * @throws {Error} If there is an error retrieving the bill or invoice.
 */
export const getBillAndInvoiceById = async (id) => {
  try {
    const snapshot = await db.collection(billAndInvoiceCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting bill and invoice:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a bill and invoice from the Firestore database.
 * @param {string} id - The ID of the bill and invoice to delete.
 * @throws {Error} If there is an error deleting the bill and invoice.
 */
export const deleteBillAndInvoice = async (id) => {
  try {
    await db.collection(billAndInvoiceCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting bill and invoice:', error.message, error.stack);
    throw error;
  }
};

const databaseBackupCollection = 'setup_databaseBackup';

/**
 * Adds a database backup to Firestore.
 * @param {Object} data - The data to be added as a database backup.
 * @param {string} data.emailFrom - The email address of the sender.
 * @param {string} data.emailTo - The email address of the recipient.
 * @returns {Promise<void>} - A promise that resolves when the database backup is added successfully.
 * @throws {Error} - If there is an error adding the database backup.
 */
export const addDatabaseBackup = async (data) => {
  try {
    let docRef = db.collection(databaseBackupCollection).doc();
    data.id = docRef.id;
    await docRef.set(data);
  } catch (error) {
    console.error('Error adding database backup:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a database backup with the specified ID.
 *
 * @param {string} id - The ID of the database backup to delete.
 * @throws {Error} If there is an error deleting the database backup.
 */
export const deleteDatabaseBackup = async (id) => {
  try {
    await db.collection(databaseBackupCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting database backup:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all database backups from Firestore.
 *
 * @param {number} [limit=10] - The maximum number of backups to retrieve.
 * @param {any} [lastItem=null] - The last item to start retrieving backups from.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of database backups.
 * @throws {Error} - If there is an error retrieving the backups.
 */
export const getAllDatabaseBackup = async (limit = 10, lastItem = null) => {
  try {
    let snapshot;
    if (lastItem) {
      snapshot = await db.collection(databaseBackupCollection).startAfter(lastItem).limit(limit).get();
    } else {
      snapshot = await db.collection(databaseBackupCollection).limit(limit).get();
    }
    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all database backup:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates a document in the database backup collection.
 * @param {string} id - The ID of the document to update.
 * @param {Object} data - The data to update the document with.
 * @throws {Error} If there is an error updating the database backup.
 */
export const updateDatabaseBackup = async (id, data) => {
  try {
    await db.collection(databaseBackupCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating database backup:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves a database backup by its ID.
 * @param {string} id - The ID of the database backup.
 * @returns {Promise<Object>} A promise that resolves to the data of the database backup.
 * @throws {Error} If there is an error retrieving the database backup.
 */
export const getDatabaseBackupById = async (id) => {
  try {
    const snapshot = await db.collection(databaseBackupCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting database backup:', error.message, error.stack);
    throw error;
  }
};

const specialFlagCollection = 'setup_specialFlag';

/**
 * Adds a special flag to the Firestore collection.
 * @param {Object} data - The data to be added to the collection.
 * @param {string} data.companyId - The ID of the company.
 * @param {string} data.group - The group of the special flag.
 * @param {string} data.firstName - The first name of Patient.
 * @param {string} data.otherName - The other name of Patient.
 * @param {string} data.phone - The Phone of Patient.
 * @param {string} data.address - The Address of Patient.
 * @returns {Promise<void>} - A promise that resolves when the data is successfully added.
 * @throws {Error} - If there is an error adding the special flag.
 */
export const addSpecialFlag = async (data) => {
  try {
    let docRef = db.collection(specialFlagCollection).doc();
    data.id = docRef.id;
    await docRef.set(data);
  } catch (error) {
    console.error('Error adding special flag:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates a special flag in the Firestore collection.
 * @param {string} id - The ID of the document to update.
 * @param {Object} data - The data to update in the document.
 * @throws {Error} If there is an error updating the special flag.
 */
export const updateSpecialFlag = async (id, data) => {
  try {
    await db.collection(specialFlagCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating special flag:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a special flag from the Firestore collection.
 * @param {string} id - The ID of the special flag to delete.
 * @throws {Error} If there is an error deleting the special flag.
 */
export const deleteSpecialFlag = async (id) => {
  try {
    await db.collection(specialFlagCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting special flag:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all special flags from Firestore.
 *
 * @param {string|null} companyId - The ID of the company to filter by. Defaults to null.
 * @param {string|null} group - The group to filter by. Defaults to null.
 * @param {number} limit - The maximum number of flags to retrieve. Defaults to 10.
 * @param {any} lastItem - The last item to start the query after. Defaults to null.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of special flags.
 * @throws {Error} - If there is an error retrieving the special flags.
 */
export const getAllSpecialFlag = async (companyId = null, group = null, limit = 10, lastItem = null) => {
  try {
    const query = db.collection(specialFlagCollection).limit(limit);

    if (companyId) {
      query.where('companyId', '==', companyId);
    }
    if (group) {
      query.where('group', '==', group);
    }

    if (lastItem) {
      query.startAfter(lastItem);
    }

    const snapshot = await query.get();

    if (snapshot.empty) {
      return []; // Early return for empty data
    }

    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all special flag:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves a special flag by its ID from Firestore.
 * @param {string} id - The ID of the special flag.
 * @returns {Promise<Object>} A promise that resolves to the special flag data.
 * @throws {Error} If there is an error retrieving the special flag.
 */
export const getSpecialFlagById = async (id) => {
  try {
    const snapshot = await db.collection(specialFlagCollection).doc(id).get();
    return snapshot.data();
  } catch (error) {
    console.error('Error getting special flag:', error.message, error.stack);
    throw error;
  }
};

const linkedAccountCollection = 'setup_linkedAccount';

/**
 * Adds a linked account to Firestore.
 *
 * @param {Object} data - The data of the linked account to be added.
 * @param {string} data.companyId - The ID of the company.
 * @param {string} data.defaultAccount - The default account of the linked account.
 * @param {string} data.linkedAccount - The linked account of the linked account.
 * @returns {Promise<void>} - A promise that resolves when the linked account is successfully added.
 * @throws {Error} - If there is an error adding the linked account.
 */
export const addLinkedAccount = async (data) => {
  try {
    let docRef = db.collection(linkedAccountCollection).doc();
    data.id = docRef.id;
    await docRef.set(data);
  } catch (error) {
    console.error('Error adding linked account:', error.message, error.stack);
    throw error;
  }
};

/**
 * Deletes a linked account from the Firestore database.
 * @param {string} id - The ID of the linked account to delete.
 * @throws {Error} If there is an error deleting the linked account.
 */
export const deleteLinkedAccount = async (id) => {
  try {
    await db.collection(linkedAccountCollection).doc(id).delete();
  } catch (error) {
    console.error('Error deleting linked account:', error.message, error.stack);
    throw error;
  }
};

/**
 * Updates a linked account in Firestore.
 * @param {string} id - The ID of the linked account.
 * @param {Object} data - The data to update the linked account with.
 * @throws {Error} If there is an error updating the linked account.
 */
export const updateLinkedAccount = async (id, data) => {
  try {
    await db.collection(linkedAccountCollection).doc(id).update(data);
  } catch (error) {
    console.error('Error updating linked account:', error.message, error.stack);
    throw error;
  }
};

/**
 * Retrieves all linked accounts from Firestore.
 *
 * @param {string|null} companyId - The ID of the company to filter the linked accounts by. Default is null.
 * @param {number} limit - The maximum number of linked accounts to retrieve. Default is 10.
 * @param {any} lastItem - The last item to start the query after. Default is null.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of linked account objects.
 * @throws {Error} - If there is an error retrieving the linked accounts.
 */
export const getAllLinkedAccount = async (companyId = null, limit = 10, lastItem = null) => {
  try {
    const query = db.collection(linkedAccountCollection).limit(limit);

    if (companyId) {
      query.where('companyId', '==', companyId);
    }

    if (lastItem) {
      query.startAfter(lastItem);
    }

    const snapshot = await query.get();

    if (snapshot.empty) {
      return []; // Early return for empty data
    }

    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.error('Error getting all linked account:', error.message, error.stack);
    throw error;
  }
};

///Storage
export const uploadLogo = async (file, userId) => {
  const storageRef = storage.ref();
  const logoRef = storageRef.child(`logos/${userId}/${file.name}`);

  try {
    await logoRef.put(file);
    const logoURL = await logoRef.getDownloadURL();
    return logoURL;
  } catch (error) {
    console.error('Error uploading logo:', error.message, error.stack);
    throw error;
  }
};
