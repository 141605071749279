// project-imports
// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAllCompanies } from '../../contexts/FirestoreServices';

export const fetchCompanies = createAsyncThunk('setups/fetchCompanies', async (_, { rejectWithValue }) => {
  try {
    const response = await getAllCompanies();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// ==============================|| SLICE - SETUPS ||============================== //

const initialState = {
  error: null,
  companies: [],
  loading: false
};

const companies = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.companies = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default companies.reducer;
