import calendar from './calendar';
import cartReducer from './cart';
// project-imports
import chat from './chat';
// third-party
import { combineReducers } from 'redux';
import company from './company';
import invoice from './invoice';
import kanban from './kanban';
import menu from './menu';
import { persistReducer } from 'redux-persist';
import productReducer from './product';
import setups from './setups';
import snackbar from './snackbar';
import storage from 'redux-persist/lib/storage';
import users from './users';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  company,
  setups,
  users,
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  kanban,
  invoice
});

export default reducers;
