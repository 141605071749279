import { APP_DEFAULT_PATH } from 'config';
// material-ui
import { ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
// project-imports
// import Logo from './LogoMain';
import PropTypes from 'prop-types';
import TjpLogo from './TjpLogo';

// import LogoIcon from './LogoIcon';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ isIcon, sx, to }) => (
  <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx}>
    {isIcon ? <TjpLogo /> : <TjpLogo />}
  </ButtonBase>
);

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
