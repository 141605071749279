// assets
import {
  Bill,
  Calendar1,
  Fatrows,
  Kanban,
  KyberNetwork,
  Messages2,
  People,
  PresentionChart,
  Profile2User,
  ShoppingBag,
  Story,
  UserSquare
} from 'iconsax-react';

// third-party
import { FormattedMessage } from 'react-intl';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  people: People,
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const tjpmenu = {
  id: 'group-applications',
  icon: icons.applications,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.calendar,
      breadcrumbs: true
    },

    {
      id: 'tj-companies',
      title: <FormattedMessage id="Companies" />,
      type: 'item',
      url: '/companies',
      icon: icons.customer,
      breadcrumbs: true
    }
  ]
};

export default tjpmenu;
